<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-toolbar color="light-blue darken-4" dark flat>

       <v-btn class="ma-2 hidden-lg-and-up" tile large color="withe" @click="setDrawer(!drawer)" icon>
      <v-icon>mdi-menu</v-icon>
    </v-btn>
      <v-spacer />
      <v-toolbar-title class="hidden-sm-and-down font-weight" v-text="$route.name" />
      <v-spacer />
      <!-- <v-btn class="ml-2" min-width="0" text to="/pages/user">
        <v-icon>mdi-account</v-icon>
      </v-btn> -->
    </v-toolbar>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar",
  data() {
    return {
      drawer: false
    };
  },
  methods: {
    setDrawer(el) {
      this.drawer = el;
      this.$emit("change", el);
    }
  }
};
</script>
